<template>
  <img class="bg-view w-100" src="../assets/bg.jpg" alt="">
  <div class="text-center">
    <a class="" type="button" href="http://i3.fortest.ren/chatbet_v3/setting/chatlink.html"
      target="_blank">
      <img class="link-btn" src="https://xhcchat.com/resource/icons/onlineButton.png" alt="">
    </a>
  </div>
  <div class="container">
    <!-- <LinkList /> -->
    <!-- <Slider /> -->
    <!-- <MainOption /> -->
    <OnlineService />
  </div>
</template>

<script>
import MainOption from "@/components/MainOption.vue";
import LinkList from "@/components/LinkList.vue";
import Slider from "@/components/Slider.vue";
import OnlineService from "@/components/OnlineService.vue";

export default {
  components: {
    MainOption,
    LinkList,
    Slider,
    OnlineService
  }
};
</script>

<style>
.bg-view {
  height: 50vh;
}

@media screen and (max-width: 650px) {
  .bg-view {
  height: 30vh;
}
}

.link-btn {
  width: 160px;
  margin-top: -150px;
}
</style>
