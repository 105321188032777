<template>
  <div class="bg-dark py-3">
    <div class="container">
      <nav>
        <div class="d-flex">
          <div class="d-flex align-items-center justify-content-center">
            <img
              class="logoImg"
              src="../assets/logo.png"
              alt=""
            />
            <!-- <h1 class="text-light m-0 fs-3 ms-2">皇冠国际永久官网</h1>； -->
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>